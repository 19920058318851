// istanbul ignore file -- Will be replaced with TopAreaBanner
import { Clear } from '@ori-ui/icons';
import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  opacity: 1,
  height: 50,
  justifyContent: 'flex-end',
  position: 'relative',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    opacity: 0.85,
  },
}));

export const TextWrapper = styled('a')(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: theme.typography.fontWeightBold,
  height: '100%',
  width: '100%',
}));

export const Text = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.2rem',
  margin: '0 auto',
  textDecoration: 'none',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
  },
}));

export const Icon = styled(Clear)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
  fontSize: '2.2rem',
  height: 50,
  margin: theme.spacing('auto', 3),
  position: 'absolute',
  right: 0,
  top: 0,
  transition: 'none',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.7rem',
  },
}));
