// istanbul ignore file -- Will be replaced with TopAreaBanner
import * as BannerClick from '@ori-events/banner-click';
import { Placement } from '@ori/analytics-data';
import { useFeatureToggles } from '@ori/feature-toggles';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';

import { HeroBannerFeature } from '../../constants';
import { useBenefitBannerData } from '../../hooks';
import { getCookie, setCookie } from '../../utils';
import { BENEFIT_BANNER } from './constants';
import { Icon, Root, Text, TextWrapper } from './styles';

const BENEFIT_BANNER_HIDDEN_COOKIE = 'benefitBannerHidden';

export const BenefitBanner: FC = memo(() => {
  const { getTestId } = useTestId();
  const [isBenefitBannerHiddenCookie, setIsBenefitBannerHiddenCookie] = useState(
    typeof window === 'undefined' ? false : Boolean(getCookie(BENEFIT_BANNER_HIDDEN_COOKIE)),
  );
  const { isFeatureEnabled } = useFeatureToggles();
  const { data, loading } = useBenefitBannerData({ isBenefitBannerHiddenCookie });

  const { messageTitle, messageUrl } = data ?? {};

  const handleAnalytics = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent<BannerClick.Data>(BannerClick.Name, {
        detail: {
          banners: [
            {
              localBannerTitle: messageTitle ?? '',
              media: [],
              target: messageUrl ?? '',
            },
          ],
          placement: Placement.ConstantBenefitBanner,
          position: 0,
        },
      }),
    );
  }, [messageTitle, messageUrl]);

  const onClick = useCallback(() => {
    setCookie(BENEFIT_BANNER_HIDDEN_COOKIE, true);
    setIsBenefitBannerHiddenCookie(true);
  }, [setIsBenefitBannerHiddenCookie]);

  if (
    loading ||
    isBenefitBannerHiddenCookie ||
    !messageUrl ||
    !messageTitle ||
    isFeatureEnabled(HeroBannerFeature.EnableTopAreaBanner)
  ) {
    return null;
  }

  return (
    <Root
      data-testid={getTestId(BENEFIT_BANNER)}
      onClick={handleAnalytics}
    >
      <TextWrapper href={messageUrl}>
        <Text>{messageTitle}</Text>
      </TextWrapper>
      <Icon
        fontSize="inherit"
        onClick={onClick}
      />
    </Root>
  );
});
