import type { ImageParams } from '@ori/image-sizes-helper';

type ImageParamsArray = ImageParams[];

/**
 * This is used to define what sizes and with what parameters should srcSet be generated.
 * It is used by `getImageVariants` function on `params` param. Function is from library `@ori/image-sizes-helper`.
 * @example
 * Here's a simple example on how to use it:
 * ```
 * const mobileImages = getImageVariants({
 *    url: "some-image-url",
 *    backgroundColor: "some-background-color",
 *    params: CAROUSEL_IMAGE_SIZES_VARIANTS.MOBILE,
 * });
 * ```
 */
export const CAROUSEL_IMAGE_SIZES_VARIANTS: { DESKTOP: ImageParamsArray; MOBILE: ImageParamsArray } = {
  MOBILE: [
    {
      width: 480,
      quality: 80,
    },
    {
      width: 540,
      quality: 80,
    },
    {
      width: 640,
      quality: 85,
    },
    {
      width: 767,
      quality: 90,
    },
  ],
  DESKTOP: [
    {
      width: 768,
      quality: 85,
    },
    {
      width: 1080,
      quality: 85,
    },
    {
      width: 1366,
      quality: 85,
    },
    {
      width: 1600,
      quality: 90,
    },
    {
      width: 1920,
      quality: 90,
    },
  ],
};
